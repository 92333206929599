declare global {
    interface Window {
        SERVER_ENV: TServerEnv
    }
}

type TServerEnv = {
    REACT_APP_BACKEND_URL: string
}

export type TGlobalConfig = {
    backendUrl: string
}

export const globalConfig: TGlobalConfig = {
    backendUrl: window.SERVER_ENV.REACT_APP_BACKEND_URL ?? process.env.REACT_APP_BACKEND_URL
}
