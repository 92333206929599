import React from 'react';
import './twopage.css';

const App: React.FC = () => {
  return (
    <div className="main-container">
      <div className='lib-header'>
        <div className='background'>
          <div className='lib-header-burger-btn'>
            <div className='horizontal-divider' />
            <div className='horizontal-divider-1' />
            <div className='horizontal-divider-2' />
          </div>
        </div>
        <span className='span-r'>р</span>
        <div className='lib-logo-link'>
          <div className='image-fill'>
            <div className='component'>
              <div className='vector' />
              <div className='vector-3' />
            </div>
          </div>
        </div>
      </div>
        
   
    

      <section className="congratulations">
        <h1>Поздравляем!</h1>
        <p>
          Ваша дата успешно зарегистрирована, и "Сертификат на внуков" почти
          готов. Благодарим за внимание к договорённости — чтобы не забыть о
          ней, мы будем периодически вам напоминать.
        </p>
      </section>

      <div className="notification">
        <img src="notification-icon.svg" alt="notification icon" />
        <p className="notification-text">
          Пожалуйста соблюдайте данную договоренность с целью избежания
          повышенного налога на бездействие.
        </p>
      </div>

      <div className="robot-image">
        <img src="/robot.png" alt="Robot" />
      </div>
    </div>
  );

};

export default App;