import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import IndexPage from './page/index';
import TwoPage from './page/twopage';  // Импортируем компонент twopage

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/twopage" element={<TwoPage />} /> {/* Создаем маршрут для twopage */}
      </Routes>
    </Router>
  );
};

// Проверка на null для элемента с id 'root'
const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<App />);
} else {
  console.error("Root element not found. Ensure your HTML file contains a div with id='root'.");
}
